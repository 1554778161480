import type {
  CheckboxProps,
  InputProps,
  RadioGroupProps,
  SelectProps,
  SwitchProps,
  TextareaProps,
} from "@chakra-ui/react";
import {
  Checkbox,
  CheckboxGroup,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  RadioGroup,
  Select,
  Stack,
  StackDivider,
  Switch,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useControlField,
  useField,
  useIsSubmitting,
} from "remix-validated-form";
import React from "react";
import { IconCross, IconView, IconViewOff } from "~/components/icons";
import { t } from "~/libs/i18n";
import z from "zod";
import { DateRangePicker } from "../datepicker/date-range-picker";
import { DatePicker } from "~/components/datepicker/date-picker.tsx";

export const repeatable = (schema = z.array(z.string())) => {
  return z.preprocess(val => {
    if (Array.isArray(val)) return val;
    if (val === undefined || val === "") return [];
    return [val];
  }, schema);
};

export function ValidatedInput(
  props: {
    label?: string;
    name: string;
    type: React.HTMLInputTypeAttribute;
    helpText?: string;
  } & InputProps,
) {
  const { label, name, type, helpText, ...rest } = props;
  const { error, getInputProps } = useField(name);
  const { isOpen: passwordVisible, onToggle } = useDisclosure();
  const isSubmitting = useIsSubmitting();

  if (type === "hidden") {
    return (
      <Input
        name={name}
        type={type}
        {...getInputProps({ id: name } as any)}
        {...rest}
        isReadOnly={isSubmitting}
      />
    );
  }

  return (
    <FormControl isInvalid={error !== undefined}>
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <Input
          name={name}
          type={passwordVisible && type === "password" ? "text" : type}
          {...getInputProps({ id: name } as any)}
          {...rest}
          isReadOnly={isSubmitting}
        />

        {type === "password" && (
          <InputRightElement>
            <IconButton
              tabIndex={-1}
              size={"sm"}
              aria-label={"toggle password visibility"}
              icon={passwordVisible ? <IconViewOff /> : <IconView />}
              onClick={onToggle}
              variant={"ghost"}
              title={
                passwordVisible
                  ? t("Password is visible. Click to hide")
                  : t("Password is hidden. Click to show")
              }
            />
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
}

export function ValidatedCheckbox(
  props: {
    label?: string | React.ReactNode;
    name: string;
    value: string;
  } & CheckboxProps,
) {
  const { label, name, value, ...rest } = props;
  const { getInputProps } = useField(name);

  return (
    <Checkbox
      id={name}
      {...getInputProps({
        type: "checkbox" as never,
        value: value as never,
      } as any)}
      {...rest}
    >
      {label}
    </Checkbox>
  );
}

export function ValidatedSwitch(
  props: {
    label?: string | React.ReactNode;
    name: string;
    value?: boolean;
  } & SwitchProps,
) {
  const { label, name, value, ...rest } = props;
  const { getInputProps, error } = useField(name);
  const { defaultValue } = getInputProps();

  return (
    <>
      <FormControl
        display="flex"
        alignItems="center"
        isInvalid={error !== undefined}
      >
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        <Switch
          id={name}
          name={name}
          {...rest}
          defaultChecked={defaultValue && defaultValue === "true"}
        />
      </FormControl>
    </>
  );
}

export const ValidatedCheckboxGroup: React.FC<{
  name: string;
  helpText?: string;
  orientation?: "row" | "column";
  children: React.ReactNode;
}> = ({ name, helpText, orientation, children }) => {
  const { error } = useField(name);
  return (
    <FormControl isInvalid={error !== undefined}>
      <CheckboxGroup>
        <Stack direction={orientation || "row"}>{children}</Stack>
      </CheckboxGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};

export const ValidatedRadioGroup: React.FC<
  {
    name: string;
    helpText?: string;
    orientation?: "row" | "column";
    children: React.ReactNode;
  } & RadioGroupProps
> = ({ name, helpText, orientation, children, ...props }) => {
  const { error } = useField(name);
  return (
    <FormControl isInvalid={error !== undefined}>
      <RadioGroup name={name} {...props}>
        <Stack direction={orientation || "row"}>{children}</Stack>
      </RadioGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};

export function ValidatedSelect(
  props: {
    label?: string;
    name: string;
    helpText?: string;
    options: { value?: string; label?: string }[];
    emptyoption?: { value?: string; label?: string };
  } & SelectProps,
) {
  const { label, name, helpText, children, onChange, ...rest } = props;
  const { error, getInputProps, validate } = useField(name);

  return (
    <FormControl isInvalid={error !== undefined}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select
        name={name}
        id={name}
        {...getInputProps()}
        {...rest}
        onChange={e => {
          validate();
          if (onChange) onChange(e);
        }}
      >
        {props.emptyoption ? (
          <option value={props.emptyoption.value}>
            {props.emptyoption.label}
          </option>
        ) : (
          <option></option>
        )}
        {props.options.map((opt, index) => (
          <option key={`${opt.value}${index}`} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{error}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
}

export const ValidatedMultiSelect = (
  props: {
    label?: string;
    name: string;
    helpText?: string;
    options: { value?: string; label?: string }[];
  } & SelectProps,
) => {
  const { label, name, helpText, children, ...rest } = props;
  const { error, validate } = useField(name);
  const [value, setValue] = useControlField<string[] | undefined>(name);

  const addToSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (!selectedValue) return;
    const sel = value ? value.concat([selectedValue]) : [selectedValue];
    setValue(sel);
    validate();
  };
  const removeFromSelection = (v: string) => {
    if (!value) return;
    const sel = value.filter(vl => vl !== v);
    setValue(sel);
    validate();
  };

  return (
    <FormControl isInvalid={error !== undefined}>
      {label && <FormLabel>{label}</FormLabel>}
      <Container p={0}>
        {value && value.length > 0 && (
          <>
            <Stack
              divider={<StackDivider borderColor={"inactive"} />}
              spacing={"sm"}
              p={"md"}
            >
              {value.map((sel, index) => (
                <HStack key={sel} w={"full"} justifyContent={"space-between"}>
                  <input
                    key={`${name}${index}`}
                    type={"hidden"}
                    name={name}
                    value={sel}
                  />
                  <Text>
                    {props.options.find(opt => opt.value === sel)?.label}
                  </Text>
                  <IconButton
                    aria-label={"remove selection"}
                    size="xs"
                    icon={<IconCross />}
                    onClick={() => removeFromSelection(sel)}
                  />
                </HStack>
              ))}
            </Stack>
            <Divider />
          </>
        )}

        <Select
          {...rest}
          borderColor={"transparent"}
          value={""}
          onChange={e => addToSelection(e)}
        >
          <option></option>
          {props.options.map((opt, index) => {
            const selected = value
              ? value.indexOf(opt.value || "") > -1
              : false;

            return (
              <option
                key={`${opt.value}${index}`}
                value={opt.value}
                disabled={selected}
              >
                {opt.label}
              </option>
            );
          })}
        </Select>
        <FormErrorMessage>{error}</FormErrorMessage>
        {helpText && <FormHelperText>{helpText}</FormHelperText>}
      </Container>
    </FormControl>
  );
};

export function ValidatedTextarea(
  props: {
    label?: string;
    name: string;
    helpText?: string;
  } & TextareaProps,
) {
  const { label, name, helpText, ...rest } = props;
  const { error, getInputProps } = useField(name);

  return (
    <FormControl isInvalid={error !== undefined}>
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea name={name} {...getInputProps()} {...rest} />
      <FormErrorMessage>{error}</FormErrorMessage>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
}

export function ValidatedDate(
  props: {
    name: string;
    label?: string;
    helpText?: string;
  } & InputProps,
) {
  const { label, helpText } = props;
  const { error: dateError, clearError: clearStartDateError } = useField(
    props.name,
  );

  return (
    <FormControl isInvalid={dateError !== undefined}>
      {label && <FormLabel>{label}</FormLabel>}
      <DatePicker
        name={props.name}
        onChange={() => {
          clearStartDateError();
        }}
      />
      <Stack>
        {dateError && <FormErrorMessage>{dateError}</FormErrorMessage>}
      </Stack>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
}

export function ValidatedDateRange(
  props: {
    label?: string;
    startInputName: string;
    endInputName: string;
    helpText?: string;
  } & InputProps,
) {
  const { label, helpText } = props;
  const { error: startDateError, clearError: clearStartDateError } = useField(
    props.startInputName,
  );
  const { error: endDateError, clearError: clearEndDateError } = useField(
    props.endInputName,
  );

  return (
    <FormControl
      isInvalid={startDateError !== undefined || endDateError !== undefined}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <DateRangePicker
        startInputName={props.startInputName}
        endInputName={props.endInputName}
        onChange={() => {
          clearStartDateError();
          clearEndDateError();
        }}
      />
      <Stack>
        {startDateError && (
          <FormErrorMessage>{startDateError}</FormErrorMessage>
        )}
        {endDateError && <FormErrorMessage>{endDateError}</FormErrorMessage>}
      </Stack>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
}

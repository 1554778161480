import { useOutsideClick } from "@chakra-ui/hooks";
import {
  Box,
  Button,
  ChakraProvider,
  Divider,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import type { CalendarDate, CalendarValues } from "datepicker";
import {
  Calendar,
  CalendarControls,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  CalendarWeek,
} from "datepicker";
import dayjs from "dayjs";
import React from "react";
import { T } from "~/libs/i18n";
import { theme } from "./date-range-picker-calendar";

function isSingleDateSelection(object: any): object is CalendarDate {
  return !("start" in object) && !("end" in object);
}

export const DatePicker = ({
  name,
  defaultValue,
  displayMonths,
  placeholder,
  onChange,
}: {
  name: string;
  defaultValue?: string | null | undefined;
  displayMonths?: number;
  placeholder?: string;
  onChange?: (dates: CalendarDate) => void;
}) => {
  const parseInputDate = (dateLike: string | null | undefined) =>
    dateLike ? dayjs(dateLike, "YYYY-MM-DD").toDate() : undefined;

  const inputDate = parseInputDate(defaultValue);

  const [date, setDate] = React.useState<CalendarDate | undefined>(inputDate);

  const [rawInputValue, setRawInputValue] = React.useState(
    inputDate && defaultValue ? defaultValue : "",
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const calendarRef = React.useRef(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const MONTHS = displayMonths || 1;

  const handleSelectDate = (date: CalendarValues | CalendarDate) => {
    if (!isSingleDateSelection(date)) {
      return;
    }

    setDate(() => date);

    setRawInputValue(
      dayjs(date, {}, true).isValid() ? dayjs(date).format("YYYY-MM-DD") : "",
    );

    if (onChange) {
      onChange(date);
    }
  };

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setRawInputValue(target.value);
  };

  const handleInputBlur = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(target.value);
    const isValidDate = dayjs(date, {}, true).isValid();

    if (!isValidDate) return;

    handleSelectDate(date);
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  return (
    <Popover
      placement="auto-start"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      isLazy
    >
      <PopoverTrigger>
        <HStack
          spacing={2}
          justifyContent={"space-between"}
          bg={"inset"}
          rounded="md"
          px={2}
          onClick={onOpen}
          ref={initialRef}
        >
          <Text color={"muted"}>{placeholder || ""}</Text>
          <Input
            size={"md"}
            name={name}
            id={name}
            value={rawInputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            ref={inputRef}
            autoComplete={"off"}
          />
        </HStack>
      </PopoverTrigger>

      <PopoverContent
        p={0}
        w="min-content"
        border="none"
        outline="none"
        _focus={{ boxShadow: "none" }}
        ref={calendarRef}
      >
        <Calendar
          singleDateSelection={true}
          value={{ start: date, end: undefined }}
          onSelectDate={handleSelectDate}
          months={MONTHS}
          allowOutsideDays
          highlightToday
        >
          <PopoverBody p={0}>
            <Box position="relative">
              <CalendarControls>
                <CalendarPrevButton />
                <CalendarNextButton />
              </CalendarControls>

              <CalendarMonths>
                {[...Array(MONTHS).keys()].map(m => (
                  <CalendarMonth key={m} month={m}>
                    <CalendarMonthName />
                    <CalendarWeek />
                    <ChakraProvider theme={theme}>
                      <CalendarDays />
                    </ChakraProvider>
                  </CalendarMonth>
                ))}
              </CalendarMonths>
            </Box>
            <Divider />
            <HStack
              spacing={1}
              bgColor="surface"
              p={4}
              justifyContent={"space-between"}
              flex={1}
            >
              <Button
                size="xs"
                onClick={() => {
                  setDate(undefined);
                  setRawInputValue("");
                }}
              >
                <T>Clear</T>
              </Button>
              <Button size="xs" onClick={onClose}>
                <T>Close</T>
              </Button>
            </HStack>
          </PopoverBody>
        </Calendar>
      </PopoverContent>
    </Popover>
  );
};
